// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs");
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('webpack-jquery-ui')
require('webpack-jquery-ui/sortable')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
//
//require("jquery")
//
require("expose-loader?$!jquery");

window.$ = $;
$(document).on("ajax:send", "[data-spinner]", function(ev) {
  $($(ev.currentTarget).data("spinner")).show();
});

$(document).on("ajax:complete", "[data-spinner]", function(ev) {
  $($(ev.currentTarget).data("spinner")).hide();
});

$(function () {
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $('#images_list').sortable(
    (
      {
        axis: 'y',
        update: function () {
          $.post($(this).data('update-url'), $(this).sortable('serialize'))
        }
      }
    )
  );

  $("a[data-audio-url]").on("click", function(ev) {
    ev.preventDefault();
    new Audio($(ev.currentTarget).data("audio-url")).play();
  });
});
